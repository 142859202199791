import axios from 'components/helpers/axios';

const url = 'api/odds';

class OddsService {
  odds(matchId, sportId) {
    return axios.get(`${url}/new/?eventId=${matchId.split(':')[2]}&sportId=${sportId}`);
  }

  saveBetsSuggestionData(model) {
    return axios.post(`${url}/saveBetsSuggestionData`, model);
  }

  getBettingCompanies(search, skip, take) {
    return axios.get(`${url}/companies?search=${search}&skip=${skip}&take=${take}`);
  }

  getBettingCompanieById(companyId) {
    return axios.get(`${url}/companies/${companyId}`);
  }

  removeBettingCompanyById(companyId) {
    return axios.delete(`${url}/companies/${companyId}`);
  }

  updateBettingCompany(dto) {
    return axios.put(`${url}/companies/`, dto);
  }

  getAllBets(filter) {
    return axios.post(`${url}/getBetsSuggestionsList`, filter);
  }

  deleteBetById(id) {
    return axios.delete(`${url}/deleteBetById?betId=${id}`);
  }

  getOddsInformationByBettingData(model) {
    return axios.post(`${url}/getOddsInformationByBettingData`, model);
  }

  getBetSuggestionByMatchId(matchId) {
    return axios.get(`${url}/getBetSuggestionByMatchId?matchId=${matchId}`)
  }
}

const service = new OddsService();
export default service;
