import styles from './bet-showcase-item.module.less';
import React from 'react';

const BetShowcaseItem = props => {
  return (
    <div className={[props.className ? props.className : '', styles.betShowcaseWrapper].join(' ')}>
      <div className={styles.betShowcaseItem}>
      <div className={styles.textItem}>
        <span className={styles.label}>{props.label}</span>
      </div>
      <div className={styles.devider}></div>
      <div>
        {props.value && (
          <span className={['p_2', styles.value, props.betType ? styles._small : ''].join(' ')}>{props.value}</span>
        )}
      </div>
        <div className={styles.bottomBorder} />
      </div>
    </div>
  );
};

export default BetShowcaseItem;
