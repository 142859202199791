import React, { Component } from 'react';
import moment from 'moment';
import styles from './match-list-item-bet-copy.module.less';
import get from 'lodash/get';
import DateHelper from 'components/helpers/date-helper';
import BetShowcaseItem from 'components/block-elements/bet-showcase-item/bet-showcase-item';
import LinkWrapper from 'components/block-elements/link-wrapper/link-wrapper';
import OddsService from 'services/odds-service';
import LogoHelper from 'components/helpers/logo-helper';
import TranslationStore from 'components/stores/translation-store';
import BokingAgencyStore from 'components/stores/booking-agency-store';
import TeamLogoImage from 'components/block-elements/team-logo-image/team-logo-image';
const UrlHelper = require('components/helpers/url-helper');

const EbettingType = [
  {id: 0, name: 'Outright'}, 
  {id: 1, name: '1-X-2'},
  {id: 4, name: 'Över/under'},
  {id: 8, name: 'Handicap'},
  {id: 10, name: 'Dubbelchans'},
  {id: 12, name: 'HT/FT'},
  {id: 17, name: 'DNB'},
  {id: 18, name: 'Both Teams To Score'},
  // {id: 32, name: 'Num Of Corners', values: null},
  {id: 999, name: 'Moneyline'}
];

export default class MatchListItemBetCopy extends Component {
  constructor(props) {
    super(props);
    // this.tvLogo = get(props, 'tvLogo.file.url');
    this.scheduled = this.props.scheduled;
    // this.scheduledDate = this.scheduled ? DateHelper.toLocalDateTime(this.scheduled) : null;
    this.isMatchStartTimePassed = DateHelper.isMatchStartTimePassed(this.props.scheduledDate);
    // this.betType = get(props, 'betType');
    // this.betValue = get(props, 'betValue');

    this.cardLabel = TranslationStore.getTranslation('match_list_item_bet_single');
    this.cutOffTime = TranslationStore.getTranslation('match_list_item_bet_cut_off_time');
    this.closedLabel = TranslationStore.getTranslation('match_list_item_bet_closed');
    this.betTypeLabel = TranslationStore.getTranslation('match_list_item_bet_bet_type');
    this.betValueLabel = TranslationStore.getTranslation('match_list_item_bet_bet_suggestion');
    this.playableFromLabel = TranslationStore.getTranslation('match_list_item_bet_playable_from');
    this.bestOddsNowLabel = TranslationStore.getTranslation('match_list_item_bet_best_odds_now');
    this.betAtLabel = TranslationStore.getTranslation('match_list_item_bet_bet_at');

    console.log(props)

    this.state = {
      bestOdds: null,
      bestOddsCompany: null,
      bestOddsCompanyLogo: null,
      oddsCompanyUrl: null,
      loadingOdds: true,
      matchTime: props?.scheduledDate,
      currentTime: null
    };
  }

  deleteBet(e, el) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    return this.props.deleteBet(el.id ? el.id : el);
  }

  getTime() {
    return Date.now()
  }

  getMatchTime() {
    return new Date(this.state.matchTime).getTime()
  }

  componentDidMount() {
    // OddsService.getBestOddsForTypeForSuggestion(this.props.matchId, this.betType, this.betValue)
    //   .then(response => {
    //     if (response === null || typeof response === 'undefined') {
    //       return;
    //     }
    //     const bestOdds = parseFloat(response.currentOdds);
    //     const bestOddsCompany = response.bookingAgencyName;
        const bestOddsCompanyLogo = LogoHelper.getBettingCompanyLogoUrlById(this.props.betCompanyId) ?
        LogoHelper.getBettingCompanyLogoUrlById(this.props.betCompanyId) : LogoHelper.getBettingCompanyLogoUrlByName(this.props.betCompanyId);
        this.setState({ bestOddsCompanyLogo: bestOddsCompanyLogo});
    //     const oddsCompanyUrl = response.deepLink;
    //     this.setState({
    //       bestOdds,
    //       bestOddsCompany,
    //       bestOddsCompanyLogo,
    //       oddsCompanyUrl,
    //       loadingOdds: false
    //     });
    //   })
    //   .catch(error => {});
  }

  getAffiliateColor(companyName) {
    switch (companyName.toLowerCase()) {
      case 'bet365':
        return styles.bet365;
      case 'bwin':
        return styles.bWin;
      case 'williamhill':
        return styles.williamHill;
      case 'oddsetsweden':
        return styles.oddset;
      default:
        return '';
    }
  }

  renderLink(match) {
    if(match.matchId?.length > 1 && match.scheduled?.length > 0 && !this.props.isChange) {
        let year = match.scheduled.substring(0, 4);
        let month = match.scheduled.substring(5, 7);
        month = month.startsWith('0') ? month.substring(1, 2) : month;
        let day = match.scheduled.substring(8, 10);
        let scheduled = year + '-' + month + '-' + day;
        let matchId = match.matchId.substring(match.matchId.lastIndexOf(':') + 1)

        const slugParts = [
          matchId,
          scheduled,
          match.defaultHomeTeamName,
          match.defaultAwayTeamName,
        ];

        return "/" + UrlHelper.convertStringToSlug(slugParts.join('-'), '-');
    } else {
      return null;
    }
  }

  render() {
    const { homeTeamName, competitorIdHome, awayTeamName, competitorIdAway } = this.props;
    const { bestOddsCompany, bestOdds, oddsCompanyUrl, bestOddsCompanyLogo } = this.state;

    return (
      <div className={styles.matchItem}>
        {/* <LinkWrapper link={this.matchUrl}> */}
            <div className={styles.matchInfoWrapper}>
            <div>
              <div>
                  {this.cardLabel && (
                    <div className={styles.label}>
                      <span className={styles.label__text}> {this.props.bettingData?.length < 2 ? this.cardLabel : 
                        this.props.bettingData?.length == 2 ? 'DUBBEL' : 
                        this.props.bettingData?.length == 3 ? 'Trippel' :
                        this.props.bettingData?.length == 4 ? 'Fyrling' :
                        this.props.bettingData?.length == 5 ? 'Femling' :
                        this.props.bettingData?.length == 6 ? 'Sexling' : 'DUBBEL'}</span>
                    </div>
                  )}
                  <div className={styles.betStop}>
                    {this.cutOffTime && <p className={styles.betStopHeadline}>{this.cutOffTime}</p>}
                    {this.props.scheduledDate && (
                      <div>
                        <p className={styles.betStopSubheading}>
                        {moment(this.props.scheduledDate)
                      .local()
                      .format('DD.MM.YYYY HH:mm')}
                        </p>
                          <div className={styles.clock}>
                            {moment.now().valueOf() < moment(this.props.scheduledDate).local().valueOf() ? <img className={styles.betStatusImage} src='https://res.cloudinary.com/dzykt0nza/image/upload/v1700503671/MCM/kolpggnsyflmwuwbth5o.png'></img>
                            : <img className={styles.betStatusImage} src='https://res.cloudinary.com/dzykt0nza/image/upload/v1700502749/MCM/dsvyk3lar2zgp3h9fgna.png'></img>}
                          </div>
                      </div>
                    )}
                  </div>
              </div>
            </div>
            {this.props.bettingData?.map(el => (
              <LinkWrapper key={el} className={styles.matchLink} link={this.renderLink(el)}>
                <div className={styles.betBox}>
                <div className={styles.sportsTypeAndTime}>
                  <img
                    className={styles.sportsType}
                    src={`/images/sportsicons/${el.sportId}.svg`}
                    alt='Sports Icon'
                  />
                  {this.scheduledDate && (
                    <div className={styles.time}>{DateHelper.getTimeFromDateObject(el.scheduled).toString()}</div>
                  )}
                </div>
                <div className={styles.teams}>
                  {el?.type != 0 && <div className={styles.league}>
                    <p>{el.tournamentName}</p>
                  </div>}
                  {el?.type != 0 ? <div className={styles.competitors}>
                  <span className={styles.competitors_teams}>
                      {el.homeTeamId ? (
                        <img src={el.homeTeamLogo} className={styles.teamLogo} />
                      ) : (
                        ''
                      )}
                      {el.homeTeamName ? el.homeTeamName : el.defaultHomeTeamName}
                    </span>
                    <span className={styles.competitors_teams}>
                      {el.awayTeamId ? (
                        <img src={el.awayTeamLogo} className={styles.teamLogo} />
                      ) : (
                        ''
                      )}
                      {el.awayTeamName ? el.awayTeamName : el.defaultAwayTeamName}
                    </span>
                  </div> : <div>
                    <div className={styles.competitors}>
                      <span className={styles.competitors_teams}>
                          {el.leagueLogo ? (
                            <img src={el.leagueLogo} className={styles.tournamentLogo} />
                          ) : (
                            ''
                          )}
                          {el.leagueName && el.leagueName}
                      </span>
                      <div className={styles.tournamentBox}>
                        <div>
                          <span style={{ fontSize: '16px' }}>{this.props.tip}</span>
                        </div>
                        <div>
                          <div>
                            Odds:
                          </div>
                          <div style={{textAlign: 'center'}}>
                            {this.props.mcmBetTo}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                    }
                </div>

                {el.value && el.type && (
                  <div className={styles.betSuggestion}>
                    {el.type && <BetShowcaseItem label={EbettingType.find(ebt => ebt.id == el.type).name} value={el.type} />}
                    {/* {el.value && <BetShowcaseItem label={this.betValueLabel} value={el.value} />} */}
                  </div>
                )}
                {el.type == 0 && <div className={styles.betSuggestion}>
                    {/* {el.type && <BetShowcaseItem label={this.betTypeLabel} value={el.competitorName} betType={el.type} />} */}
                    {/* {JSON.stringify(el)} */}
                    <BetShowcaseItem label={'VINNARE'} value={el.homeTeamName} />
                  </div>
                  
                }
                {this.props.isChange && <div className={styles.delButton}>
                  <button onClick={(e) => this.deleteBet(e, el)}>DEL</button>
                </div>}
                </div>
              </LinkWrapper>
        ))}
        </div>
        {/* </LinkWrapper> */}
        <div className={styles.betInfoWrapper}>
          <div>
            {this.props.betStatus == 0 && <img className={styles.betStatusImage} src='https://res.cloudinary.com/dzykt0nza/image/upload/v1700501372/MCM/fqhm4ima8u2k2dvng6ph.svg'></img>}
            {this.props.betStatus == 1 && <img className={styles.betStatusImage} src='https://res.cloudinary.com/dzykt0nza/image/upload/v1700501416/MCM/kn8tkl2jceey6sju4nr3.svg'></img>}
            {this.props.betStatus == 2 &&<img className={styles.betStatusImage} src='https://res.cloudinary.com/dzykt0nza/image/upload/v1700502101/MCM/fyo0glwefszluxr4ppwc.svg'></img>}
          </div>
          <div className={styles.playableFromWrapper}>
            {this.props.coefficient && <p className={styles.smallHeadline}>{this.playableFromLabel}</p>}
            {this.props.coefficient && <span className={styles.oddsNumber}>{this.props.coefficient.toFixed(2)}</span>}
          </div>
          <div className={styles.bestOddsWrapper}>
            {this.bestOddsNowLabel && <p className={styles.smallHeadline}>{this.bestOddsNowLabel}</p>}
            {this.props.bestOdds ? (
              <span
                className={[
                  styles.oddsNumber,
                  this.props.bestOdds >= this.props.coefficient ? styles.oddsNumber__green : styles.oddsNumber__red,
                ].join(' ')}>
                {this.props.bestOdds && this.props.bestOdds.toFixed(2)}
              </span>
            ) : (
              <span className={[styles.oddsNumber].join(' ')}>{'-'}</span>
            )}
          </div>
          <LinkWrapper link={oddsCompanyUrl}>
            <div
              className={[
                styles.affiliateLinkWrapper,
                this.props.bestOddsCompany ? this.getAffiliateColor(this.props.bestOddsCompany) : '',
              ].join(' ')}>
              {this.betAtLabel && <p className={styles.betCompanyName}>{this.betAtLabel}</p>}
              {bestOddsCompanyLogo ? (
                <img src={bestOddsCompanyLogo} alt='' className={styles.betIcon} />
              ) : (
                <span className={[styles.oddsNumber].join(' ')}>{'-'}</span>
              )}
            </div>
          </LinkWrapper>
        </div>
      </div>
    );
  }
}


// id: '',
// winnerTeamId: '',
// logo: '',
// name: '',
// leagueLogo: props.leagueLogo,
// leagueName: props.leagueName,
// leagueId: props.tournamentId,
// sportId: props.sportName,
// type: null,