
class IntersectionObserverImageHelper{

    constructor() {
        if (typeof window !== 'undefined' && 'IntersectionObserver' in window) {
            this.intersectionObserver = new IntersectionObserver((entries) => entries.forEach((entry) => this.isElementIntersecting(entry)));
        }
    }

    isElementIntersecting(entry){
        if (entry && entry.isIntersecting) {
            entry.target.src = entry.target.dataset.src;
            this.unobserve(entry.target);
        }
    }

    observe(el){
        if(typeof(el) === 'Element')
            this.intersectionObserver.observe(el);
    }

    unobserve(el){
        if(typeof(el) === 'Element')
            this.intersectionObserver.unobserve(el);
    }

    observeElements(elements){
        "IntersectionObserver" in window ? elements.forEach((el) => this.observe(el)) : this.fallback(elements);
    }

    unobserveElements(elements){
        if("IntersectionObserver" in window){
            elements.forEach(el => this.unobserve(el))
        }
    }

    fallback(elements){
        for(let i = 0, iMax = elements.length; i < iMax; i++){
            elements[i].src = elements[i].dataset.src
        }
    }
}

const singleton = new IntersectionObserverImageHelper();
export default singleton;