import React, { Component } from 'react';
import IntersectionObserverImageHelper from 'components/helpers/intersection-observer-image-helper';
import TeamLogoHelper from 'components/helpers/team-logo-helper'
import {svgs, jpgs, pngs} from 'components/helpers/files-array-svg';

export default class TeamLogoImage extends Component {

    constructor(props){
        super(props);
        this.defaultPosterSrc = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';
        this.ref_image = React.createRef();
        this.trySvgOnError = true;
        this.tryPngOnError = true;
        this.onErrorSrc = '';
        this.teamLogo = '';
        this.state = {
            onErrorSrc: '',
            teamLogo: ''
        }
    
        this.ref_image = `${process.env.SITE_URL != undefined ? process.env.SITE_URL : 'https://www.matchcentermedia.se/'}images/teamlogos/${this.props.competitorId.split(':')[2]}.png`;
    }
    
    componentDidMount(){
        this.setState({teamLogo: `${process.env.SITE_URL != undefined ? process.env.SITE_URL : 'https://www.matchcentermedia.se/'}images/teamlogos/${this.props.competitorId.split(':')[2]}.png`});
        this.setState({onErrorSrc: `${process.env.SITE_URL != undefined ? process.env.SITE_URL : 'https://www.matchcentermedia.se/'}images/teamlogos/${this.props.competitorId.split(':')[2]}.png` })
        // if(this.props.competitorId){
        //     IntersectionObserverImageHelper.observeElements([this.ref_image.current])
        // }
        
        // TeamLogoHelper.getEnetpulseSvg(this.props.competitorId).then(response => {
        //       response.json().then((r) => {
        //             if(Object.values(r.images).filter(i => i.contenttype === 'image/svg+xml').length > 0)
        //                 this.image = `data:image/svg+xml;base64,${Object.values(r.images).filter(i => i.contenttype === 'image/svg+xml')[0].value}`
        //             else
        //                 this.image = `data:image/png;base64,${Object.values(r.images).filter(i => i.contenttype === 'image/png')[1].value}`
        //       })
        //       .catch((e) => {
        //       })
        // });
            
        // if(this.ref_image && this.ref_image.current){
        //     this.trySvgOnError = false;
            
        //     let fileName = this.props.competitorId.split(':').join('_');
            
        //     let pngExists = pngs.find(item => {
        //         return item === `${fileName}.png`;
        //     });
        //     let svgExists = svgs.find(item => {
        //         return item === `${fileName}.svg`;
        //     });
            
        //     // if(pngExists !== undefined){
        //     //     this.teamLogo = TeamLogoHelper.getTeamLogoPngSrc(this.props.competitorId);
        //     //     this.setState((state, props) => {
        //     //         state.teamLogo = TeamLogoHelper.getTeamLogoPngSrc(this.props.competitorId)
        //     //     })
        //     // } else if (svgExists !== undefined){
        //     //     this.teamLogo = TeamLogoHelper.getTeamLogoSrc(this.props.competitorId);
        //     //     this.setState((state, props) => {
        //     //         state.teamLogo = TeamLogoHelper.getTeamLogoSrc(this.props.competitorId)
        //     //     })
        //     // } else {
        //     //     // TeamLogoHelper.getEnetpulseSvg(this.props.competitorId)
        //     //     // .then(response => {
        //     //     //     response.json().then((r) => {
        //     //     //         let svg = Object.values(r.images).filter(i => i.contenttype === 'image/svg+xml')[0];
        //     //     //         let png = Object.values(r.images).filter(i => i.contenttype === 'image/png')[0];
                        
        //     //     //         if(svg){
        //     //     //             let base64TeamLogo = `data:image/svg+xml;base64,${Object.values(r.images).filter(i => i.contenttype === 'image/svg+xml')[0].value}`
        //     //     //             // debugger
        //     //     //             this.ref_image.current.src = (base64TeamLogo ? base64TeamLogo : '');
        //     //     //             this.setState((state, props) => {
        //     //     //                 state.teamLogo = base64TeamLogo
        //     //     //             })
        //     //     //             // this.onErrorSrc = onErrorSrc;
        //     //     //             // this.setState((state, props) => {
        //     //     //             //     state.onErrorSrc = onErrorSrc;
        //     //     //             // })
        //     //     //         } else if (png){
        //     //     //             let base64TeamLogo = `data:image/png;base64,${Object.values(r.images).filter(i => i.contenttype === 'image/png')[0].value}`;
        //     //     //             // debugger
        //     //     //             this.ref_image.current.src = (base64TeamLogo ? base64TeamLogo : '');
        //     //     //             this.setState((state, props) => {
        //     //     //                 state.teamLogo = base64TeamLogo
        //     //     //             })
        //     //     //             // this.onErrorSrc = onErrorSrc;
        //     //     //             // this.setState((state, props) => {
        //     //     //             //     state.onErrorSrc = onErrorSrc;
        //     //     //             // })
        //     //     //         }
        //     //     //     });
        //     //     // })
        //     //     // .catch(error => {
        //     //     //     console.error(`Failed to load image for ${this.props.competitorId}`)
        //     //     // })
        //     //     this.state.teamLogo = `images/teamlogos/${this.props.competitorId.split(':')[2]}.png`;
        //     // }
        //     this.state.teamLogo = `images/teamlogos/${this.props.competitorId.split(':')[2]}.png`;
        //     this.onErrorSrc = `images/teamlogos/${this.props.competitorId.split(':')[2]}.png`;
        //     this.ref_image = `images/teamlogos/${this.props.competitorId.split(':')[2]}.png`;
            
        //     // let teamLogo = TeamLogoHelper.getTeamLogoSrc(this.props.competitorId);
            
        //     // // if(teamLogo.includes('388'))
        //     // //     debugger

        //     // fetch(`${teamLogo}`)
        //     //     .then(
        //     //         (response) => {
        //     //             if(response.status === 200){
        //     //                 response.text()
        //     //                 .then(text => {
        //     //                     if(!text.includes('DOCTYPE')){
        //     //                         this.teamLogo = teamLogo;
        //     //                         this.setState((state, props) => {
        //     //                             state.teamLogo = teamLogo
        //     //                         })
        //     //                     } else {
        //     //                         teamLogo = TeamLogoHelper.getTeamLogoPngSrc(this.props.competitorId);
                            
        //     //                         fetch(`${teamLogo}`)
        //     //                             .then(response => {
        //     //                                 if(response.status === 200){
        //     //                                     response.text()
        //     //                                     .then(text => {
        //     //                                         if(!text.includes('DOCTYPE')){
        //     //                                             this.teamLogo = teamLogo;
        //     //                                             this.setState((state, props) => {
        //     //                                                 state.teamLogo = teamLogo
        //     //                                             })
        //     //                                         } else {
        //     //                                             TeamLogoHelper.getEnetpulseSvg(this.props.competitorId)
        //     //                                             .then(response => {
        //     //                                                 response.json().then((r) => {
        //     //                                                     let svg = Object.values(r.images).filter(i => i.contenttype === 'image/svg+xml')[0];
        //     //                                                     if(svg){
        //     //                                                         let base64TeamLogo = `data:image/svg+xml;base64,${Object.values(r.images).filter(i => i.contenttype === 'image/svg+xml')[0].value}`
        //     //                                                         // debugger
        //     //                                                         this.ref_image.current.src = base64TeamLogo;
        //     //                                                         this.setState((state, props) => {
        //     //                                                             state.teamLogo = base64TeamLogo
        //     //                                                         })
        //     //                                                         // this.onErrorSrc = onErrorSrc;
        //     //                                                         // this.setState((state, props) => {
        //     //                                                         //     state.onErrorSrc = onErrorSrc;
        //     //                                                         // })
        //     //                                                     }
        //     //                                                 });
        //     //                                             })
        //     //                                             .catch(error => {
        //     //                                             })
        //     //                                         }
        //     //                                     })
        //     //                                 }
        //     //                             })
        //     //                     }
        //     //                 })
        //     //             }
        //     //         })
        //         // .catch(error => {
        //         //     teamLogo = TeamLogoHelper.getTeamLogoPngSrc(this.props.competitorId);
                    
        //         //     fetch(`${teamLogo}`)
        //         //         .then(response => {
        //         //             this.teamLogo = teamLogo;
        //         //         })
        //         //         .catch(error => {
        //         //             TeamLogoHelper.getEnetpulseSvg(this.props.competitorId)
        //         //             .then(response => {
        //         //                 debugger
        //         //                 response.json().then((r) => {
        //         //                     let svg = Object.values(r.images).filter(i => i.contenttype === 'image/svg+xml')[0];
        //         //                     if(svg){
        //         //                         this.teamLogo = `data:image/svg+xml;base64,${Object.values(r.images).filter(i => i.contenttype === 'image/svg+xml')[0].value}`
        //         //                         // this.onErrorSrc = onErrorSrc;
        //         //                         // this.setState((state, props) => {
        //         //                         //     state.onErrorSrc = onErrorSrc;
        //         //                         // })
        //         //                     }
        //         //                 });
        //         //             })
        //         //             .catch(error => {
        //         //             })
        //         //         })
        //         // })
        // }
        // if(this.ref_image && this.ref_image.current){
        //     if(this.trySvgOnError){
        //         // debugger
        //         this.trySvgOnError = false;
        //         // this.ref_image.current.src = TeamLogoHelper.getTeamLogoSrc(this.props.competitorId);
        //         this.onErrorSrc = TeamLogoHelper.getTeamLogoSrc(this.props.competitorId);
        //         this.setState((state, props) => {
        //             state.onErrorSrc = TeamLogoHelper.getTeamLogoSrc(this.props.competitorId);
        //         })
        //     }
        //     else if(this.tryPngOnError){
        //         // debugger
        //         this.tryPngOnError = false;
        //         // this.ref_image.current.src = TeamLogoHelper.getTeamLogoPngSrc(this.props.competitorId);
        //         this.onErrorSrc = TeamLogoHelper.getTeamLogoPngSrc(this.props.competitorId);
        //         this.setState((state, props) => {
        //             state.onErrorSrc = TeamLogoHelper.getTeamLogoPngSrc(this.props.competitorId);
        //         })
        //     }
        //     else{
        //         debugger
        //         if(!this.props.competitorId.startsWith('sr')){
        //             TeamLogoHelper.getEnetpulseSvg(this.props.competitorId)
        //             .then(response => {
        //                 // debugger
        //                 response.json().then((r) => {
        //                     let svg = Object.values(r.images).filter(i => i.contenttype === 'image/svg+xml')[0];
        //                     if(svg){
        //                         let onErrorSrc = `data:image/svg+xml;base64,${Object.values(r.images).filter(i => i.contenttype === 'image/svg+xml')[0].value}`
        //                         this.onErrorSrc = onErrorSrc;
        //                         this.setState((state, props) => {
        //                             state.onErrorSrc = onErrorSrc;
        //                         })
        //                     }
        //                 });
        //             })
        //             .catch(error => {
        //                 console.error(`Failed to load image for ${this.props.competitorId}`)
        //             })
        //         } else {
        //             console.error(`Attempt to retrieve Sportradar ID ${this.props.competitorId}`);
        //         }
        //     }
        // }
    }

    componentWillUnmount(){
        if(this.props.competitorId){
            IntersectionObserverImageHelper.unobserveElements([this.ref_image.current]);
        }
    }

    setImageIsAbsent = async () => {
        let image = await TeamLogoHelper.getEnetpulseTeamLogo(this.props.competitorId);
        this.setState({ teamLogo: image});
    }

    render(){
        // if(this.state.teamLogo.startsWith('data:image'))
        //     debugger

        return this.props.competitorId ? 
                <img ref={this.ref_image} 
                    key={this.props.competitorId}
                    onError={this.setImageIsAbsent}
                    role="presentation"
                    id={this.props.competitorId}
                    className={this.props.className ? this.props.className : ""} 
                    src={this.state.teamLogo} 
                //     // src={this.props.competitorId.startsWith('e:') ? this.image : this.defaultPosterSrc}
                //     // data-src={TeamLogoHelper.getTeamLogo(this.props.competitorId)} 
                //     alt={this.props.alt ? this.props.alt : ""} 
                    /*onError={(e,b,c)=>{ */
                    //     debugger
                        // if(this.ref_image && this.ref_image.current){
                            // if(this.trySvgOnError){
                            //     // debugger
                            //     this.trySvgOnError = false;
                            //     this.ref_image.current.src = TeamLogoHelper.getTeamLogoSrc(this.props.competitorId);
                            // }
                            // else if(this.tryPngOnError){
                            //     // debugger
                            //     this.tryPngOnError = false;
                            //     this.ref_image.current.src = TeamLogoHelper.getTeamLogoPngSrc(this.props.competitorId);
                            // }
                            // else{
                                // debugger
                                // this.ref_image.current.src = image;
                            // }
                        // }
                        
                    /*}}*/ /> 
                // <div>test</div>
                    : 
                    ""
    }
}