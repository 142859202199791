const ImageStore = require('../stores/image-store')
const TeamStore = require('../stores/team-store')


class TeamLogoHelper {

  getTeamLogo(competitorId){
    // debugger
    if(!competitorId){
      this.getFallBackTeamLogo();
    }

    // return TeamStore.getTeamLogo(competitorId) ? TeamStore.getTeamLogoEnetpulse(competitorId) : this.getTeamLogoFromSportRadar(competitorId)
    // return TeamStore.getTeamLogoEnetpulse(competitorId)
    return TeamStore.getTeamLogo(competitorId);
  }


  async getEnetpulseTeamLogo(id) {
    return await TeamStore.getTeamLogoEnetpulse(id.replace('e:competitor:', ''));
  }

  getFallBackTeamLogo(){
    return ImageStore.getImage("fallback_image_teamlogo")
  }

  getTeamLogoSrc(competitorId) {
    return `/images/teamlogos/${competitorId.split(':').join('_')}.svg`;
  }

  getTeamLogoPngSrc(competitorId){
    return `/images/teamlogos/${competitorId.split(':').join('_')}.png`;
  }

  getTeamLogoFromSportRadar(competitorId) {
    // debugger
    // return `https://ls.sportradar.com/ls/crest/big/${this.getCleanSportRadarId(competitorId)}.png`;
    return `https://eapi.enetpulse.com/image/team_logo/?teamFK=${this.getCleanSportRadarId(competitorId)}&username=matchcenterapiusr&token=42fc5f26354966eaa1b2255e6647c795`;
  }

  getCleanSportRadarId(competitorId) {
    return competitorId && competitorId.lastIndexOf(':') > -1 ? competitorId.substring(competitorId.lastIndexOf(':') + 1, competitorId.length) : competitorId;
  }
  
  getTeamLogoSvg(competitorId){
    let image = ''
    fetch('https://eapi.enetpulse.com/image/team_logo/?teamFK=8455&username=matchcenterapiusr&token=42fc5f26354966eaa1b2255e6647c795')
      .then(response => {
        // debugger
        let i = response.json().then((r) => {
          image = `data: image/svg+xml; base64 ${Object.values(r.images).filter(i => i.contenttype === 'image/svg+xml')[0].value}`
        });
        
      })
      .catch(e => {
        console.error(e);
      })
    return image;
  }
  
  getEnetpulseSvg(competitorId){
    // let id = this.getCleanSportRadarId(competitorId)
    // // debugger
    // return fetch(`http://eapi.enetpulse.com/image/team_logo/?teamFK=${id}&username=matchcenterapiusr&token=42fc5f26354966eaa1b2255e6647c795`)
      // .then(response => {
      //   response.json().then((r) => {
      //     image = `data: image/svg+xml; base64 ${Object.values(r.images).filter(i => i.contenttype === 'image/svg+xml')[0].value}`
      //     return image;
      //   });
        
      // })
      // .catch(e => {
      //   console.error(e);
      // })
    // let image = ''
    // debugger
    return fetch(`https://eapi.enetpulse.com/image/team_logo/?teamFK=${this.getCleanSportRadarId(competitorId)}&username=matchcenterapiusr&token=42fc5f26354966eaa1b2255e6647c795`)
    //   .then(response => {
    //     // debugger
    //     let i = response.json().then((r) => {
    //       image = `data: image/svg+xml; base64 ${Object.values(r.images).filter(i => i.contenttype === 'image/svg+xml')[0].value}`
    //     });
        
    //   })
    //   .catch(e => {
    //     console.error(e);
    //   })
    // return image;
      
  }
  
  getTeamLogoPng(competitorId){
    debugger
    return `/images/teamlogos/${competitorId.split(':').join('_')}.svg`;
  }
}

const helper = new TeamLogoHelper();
export default helper;
